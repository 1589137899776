import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, Avatar, Paper } from '@mui/material';
import axios from 'axios';

const StreamerPage = () => {
  const { channelName } = useParams();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchStreamerData = async () => {
      try {
        const userResponse = await axios.get(`/api/twitch/user/${channelName}`, { withCredentials: true });
        setUserInfo(userResponse.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchStreamerData();
  }, [channelName]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://player.twitch.tv/js/embed/v1.js";
    script.async = true;
    script.onload = () => {
      if (window.Twitch && window.Twitch.Player) {
        const options = {
          width: "100%",
          height: "100%",
          channel: channelName,
          parent: ["scurvy.dasker.co.uk"]
        };
        const player = new window.Twitch.Player("twitch-embed", options);
        player.setVolume(0.5);
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [channelName]);

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item>
          {userInfo && <Avatar src={userInfo.profile_image_url} sx={{ width: 80, height: 80 }} />}
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {channelName}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{ height: { xs: 'calc(100vh - 128px - 130px)', md: 'calc(100vh - 128px - 130px)' } }}>
        <Grid item xs={12} md={6.5} sx={{ height: { xs: '50%', md: '100%' } }}>
          <Box id="twitch-embed" sx={{ height: '100%' }}></Box>
        </Grid>
        <Grid item xs={12} md={2.5} sx={{ height: { xs: '50%', md: '100%' } }}>
          <Box sx={{ height: '100%' }}>
            <iframe
              id="twitch-chat-embed"
              src={`https://www.twitch.tv/embed/${channelName}/chat?parent=scurvy.dasker.co.uk&darkpopout&background-color=rgba(0,0,0,0.8)`} // Custom background color
              height="100%"
              width="100%"
              frameBorder="0"
            ></iframe>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} sx={{ height: { xs: '50%', md: '100%' }, paddingLeft: { md: 2, xs: 0 } }}>
          <Paper sx={{ height: '100%', overflowY: 'auto', backgroundColor: '#424867', padding: 2 }}>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>Scurvy Actions</Typography>
            {/* Add scurvy actions here */}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StreamerPage;
